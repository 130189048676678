import React from "react";
import axios from 'axios';

import PostApi from "./PostApi.jsx";
// import SortSelectTbl from "./SortSelectTbl.jsx";

// import main from '../../public/main.png'; 
import PageviewIcon from '@mui/icons-material/Pageview';
import { withOktaAuth } from "@okta/okta-react";

import NotFound from './NotFound'

import Button from '@mui/material/Button';

import MUIDataTable from "mui-datatables";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import StarsIcon from '@mui/icons-material/Stars';
import ErrorIcon from '@mui/icons-material/Error';

export const api_url="https://20211205t040925-dot-my-comment-api-dot-mingfei-test-account.appspot.com/api/comments" //"https://20211205t040925-dot-my-comment-api-dot-mingfei-test-account.appspot.com/api/comments" "http://localhost:8080/api/comments"

export var axiosdata =function getNewData(api_url){
    return axios.get(api_url)
}

async function checkUser() {
    if (this.props.authState.isAuthenticated && !this.state.userInfo) {
      const userInfo = await this.props.authService.getUser();
      if (this._isMounted) {
        this.setState({ userInfo });
      }
    }
  }
  

function dateSubstring(myDate) {  
  return String(myDate).substring(0,10)
}
  


export default withOktaAuth ( class FetchMingfeiApi extends React.Component {
  _isMounted = false;

  constructor(props){
      super(props)

      this.state = {
          _id: "",
          name: "React",
          apiData: [],
          loading:true,
          userInfo: "",
          showAddReview:false,
          // stockPrice:null,

      };
      this.checkUser = checkUser.bind(this);
      this.hideComponent = this.hideComponent.bind(this);

      this.local_url = process.env.LOCAL_URL || 'http://localhost:3000';
      
    }
  
  hideComponent(name) {
    switch (name) {
      case "showHideMyReview":
        this.setState({ showAddReview: !this.state.showAddReview });
        break;
      default: this.setState({showAddReview:false}) 
    }
  }

  async componentDidMount(){

      // const test_number = getRandomArbitrary(3);
      axiosdata(api_url).then(res => {
        var i, my_url;
        for(i=0; i < res.data.length; i++){
          my_url=`${this.local_url}/comments/${res.data[i]._id}` // index
          // eslint-disable-next-line

          res.data[i].url=(res.data[i].Rating>6?<Button href={my_url} style={{color:"green"}}><StarsIcon/></Button>:res.data[i].Rating<3?<Button href={my_url} style={{color:"pink"}}><ErrorIcon/></Button>:<Button href={my_url}><KeyboardArrowRightIcon/></Button>)
          // {res.data[i].Brand}
          res.data[i].updatedAt=dateSubstring(res.data[i].updatedAt)
          res.data[i].diffDays = Math.ceil(Math.abs(new Date() - new Date(res.data[i].updatedAt).getTime()) / (1000 * 60 * 60 * 24)); 
        }
        this.setState({apiData: res.data, loading: false})
        // console.log(this.state.apiData)

      })
        .catch(error => {
          console.log(error)
        });
        
      this._isMounted = true;
      this.checkUser();
      

  }
  componentWillUnmount() {
      this._isMounted = false;
    }
      
  
  render(){

      if (this.state.loading) {
          return <div><NotFound /></div>
      }

      if (!this.state.apiData) {
          return <div>did not get any data</div>
      }
      const columns = [
        {
          name: "url",
          label: "Select",
          options: {
          filter: false,
          sort: true,
          }
        },
        {
          name: "Brand",
          label: "Symbole",
          options: {
          filter: true,
          sort: true,
          }
        },
        {
          name: "Description",
          label: "Title",
          options: {
          filter: false,
          sort: false,
          }
        },
        {
          name: "Rating",
          label: "Rating",
          options: {
          filter: true,
          sort: true,
          // customBodyRender: (value, tableMeta, updateValue) => {
          //   const nf = new Intl.NumberFormat("en-US", {
          //     style: "currency",
          //     currency: "USD",
          //     minimumFractionDigits: 2,
          //     maximumFractionDigits: 2
          //   });

          //   return nf.format(value);
          // }
          }
        },
        {
          name: "comment_name",
          label: "Comment",
          options: {
          filter: false,
          sort: false,
          }
        },
        {
          name: "updatedAt",
          label: "Date",
          options: {
            filter: false,
            sort: true,
            // sortDirection: 'desc'
          }
          },
          {
          name: "diffDays",
          label: "Age(Days)",
          options: {
            filter: false,
            sort: true,

          }
          },
          {
          name: "user_name",
          label: "User",
          options: {
            filter: true,
            sort: false,
          }
          }
        ];
      
  
      const options = {
      filter: true,
      filterType: 'dropdown',
      print: false,
      viewColumns: false,
      selectableRows: 'none',
      responsive: "standard",
      sortOrder: {
        name: 'updatedAt',
        direction: 'desc'
      }
      };

      const { showAddReview } = this.state;

      const divStyle = {
        marginTop: 25,
        marginBottom: 25,
        marginLeft: 0
      };

      // console.log(this.state.userInfo.name)

  return <div>

      {this.state.loading || !this.state.apiData || !this.state.userInfo? (<div>loading...if pending to long, refresh the page</div>
      ):(
          <div>

          <p><PageviewIcon /> Search History as below</p>
          <section>

          {!showAddReview && this.state.userInfo.name==="Mingfei JIANG" ?   
          <Button onClick={() => this.hideComponent("showHideMyReview")} 
          variant="contained" 
          style={divStyle}
          >
          Add
          </Button>
          :!showAddReview && this.state.userInfo.name!=="Mingfei JIANG" ?   
          <Button onClick={() => this.hideComponent("showHideMyReview")} 
          variant="contained" 
          style={divStyle}
          disabled
          >
          Add
          </Button> : 
          <Button onClick={() => this.hideComponent("showHideMyReview")} 
          variant="contained" 
          style={divStyle}
          >
          Cancel
          </Button>} 

          {showAddReview && <PostApi/>}
          </section>
             
          
          </div>


          )}
          {/* <img src={main} alt="robot" /> */}


      

      <section>
      <MUIDataTable
      title={"HISTORY"}
      data={this.state.apiData}
      columns={columns}
      options={options}
      />
      </section> 

      </div>
      
  };

}
)

