import React from 'react'

// import { css } from "@emotion/core";
// import DotLoader from "react-spinners/DotLoader";

import CircularProgress from '@mui/material/CircularProgress';

// const override = css`
//   display: block;
//   margin: 0 auto;
//   border-color: red;
// `;


export default class AwesomeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
 
  render() {
    return (
      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', margin:30}}>
        <CircularProgress color="secondary" size={68}/>

        {/* <DotLoader
          css={override}
          size={150}
          color={"#123abc"}
          loading={this.state.loading}
        /> */}
      </div>
    );
  }
}






// import Container from '@mui/material/Container';
// import Button from '@mui/material/Button';
// import { useOktaAuth } from '@okta/okta-react';


// const NotFound = () => {
//     const { authService } = useOktaAuth();
//     const login = async () => {
//         authService.login('/');
//       };

//     return (
//         <div>
//         <Container style={{ marginTop: '7em'}}>

//             <p>Try to Login...</p>
//             <Button id="login-button" color="secondary" onClick={login}>Login</Button>


//         </Container>
//         </div>
//     )
// }

// export default NotFound
