import React from 'react';

import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { api_url } from './FetchMingfeiApi'

import FormControl from '@mui/material/FormControl';

// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';

import { withOktaAuth } from "@okta/okta-react";
import InputAdornment from '@mui/material/InputAdornment';

import AccountCircle from '@mui/icons-material/AccountCircle';

import stockfile from "../data/stock_list.json";


// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;

// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };



async function checkUser() {
  if (this.props.authState.isAuthenticated && !this.state.userInfo) {
    const userInfo = await this.props.authService.getUser();
    if (this._isMounted) {
      this.setState({ userInfo });
    }
  }
}


export default withOktaAuth (class CommentList extends React.Component {

  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      user_name: '', 
      comment_name: '',
      Description:'',
      Rating:'',
      stockList:[],
      Brand: '',
      userInfo: null,
      };
    this.checkUser = checkUser.bind(this);


  }


  async componentDidMount() {
    this._isMounted = true;
    this.checkUser();

    var arr = [];
    Object.keys(stockfile).forEach(function(key) {
      arr.push({"label":stockfile[key]["Symbol"], "value":stockfile[key]["Name"]});
    });
    // console.log(arr);
    this.setState({stockList:arr})

  }






  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChangeSelect=(event,newValue) => {
    this.setState({ Brand: newValue })
    // console.log(this.state.stockList)
  }

  handleChange = event => {
    const value = event.target.value;
    this.setState({ 
        [event.target.name]: value
          });
  }


  handleSubmit = event => {
    event.preventDefault();

    const comment_name = this.state.comment_name;
    const user_name = this.state.userInfo.name;
    const Rating = this.state.Rating;
    const Description = this.state.Description;
    const Brand = this.state.Brand;

  if (this.state.Brand && this.state.Brand.length!==0) {
    axios.post(api_url, { comment_name, user_name, Rating, Description, Brand })
      .then(res => {
        console.log(res);
        console.log(res.data);
        // console.log(document.getElementById('google-map-location').value);
      })
      .catch(error => {
        console.log(error.response)
    })

    setTimeout(() => {  window.location.reload(); }, 2500);
  }
  else {console.log(this.state.Brand+" Brand is missing or incorrect")}
  }

  

  
  render() {

    return (
      <div>

      <FormControl
      style={{
        display: "block",
        // textAlign: 'left',
        margin:'10px',
        // padding:'15px',
      }} 
      >

      {this.state.userInfo && (
      <TextField disabled
      id="myName"        
      label="User"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <AccountCircle />
          </InputAdornment>
        ),
      }}
      name="user_name" 
      variant='standard'
      defaultValue={this.state.userInfo.name} 
      onChange={this.handleChange}/>   
      ) 
      } 

      <Autocomplete
      id="myBrand"
      inputValue={this.state.Brand}
      onInputChange={this.handleChangeSelect}
      freeSolo
      required
      options={this.state.stockList}
      getOptionLabel={(option) => option.label}
      renderOption={(props,option) => (
      <li {...props}>{option.label}&nbsp;&nbsp;&nbsp;{option.value}</li>
      )}
      style={{maxWidth:800, minWidth:300, marginBottom:10}}
      renderInput={(params) => <TextField {...params} label="Stock" margin="normal" />}
      />

        <TextField 
        id="title"        
        label="Title"
        variant='standard'
        name="Description"
        required 
        multiline
        maxRows={4}
        fullWidth
        style={{marginBottom:10}}
        value={this.state.Description} onChange={this.handleChange} />

        <TextField id="myNumber" label="Rating(0~10)"  
        variant='standard' 
        type="number" 
        name="Rating" s
        style = {{textAlign:'right', width: 200, marginBottom:10}}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', inputProps:{max:10, min:0} }}
        // InputProps={{
        // inputProps: { 
        //     max: 10, min: 0 
        //   }
        // }} 
        value={this.state.Rating} onChange={this.handleChange} />

        {/* <GoogleMap /> */}

        <TextField 
        required 
        id="myComment" 
        label="Review"
        multiline 
        rows={4} 
        name="comment_name"
        style = {{marginBottom:10}}
        fullWidth
        value={this.state.comment_name} 
        onChange={this.handleChange} 
        />    
    
        
        <Button variant="contained" color="secondary" type="submit" onClick={this.handleSubmit} >Share</Button>
        </FormControl>

      </div>
    )
  }
}
)
