import { useOktaAuth } from '@okta/okta-react';

import useClasses from './UseClass';

import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';

import "./css/Home.css";
import sunny from '../public/sunny.png';
import cloudy from '../public/cloudy.png';
import rainy from '../public/rainy.png';
import stormy from '../public/stormy.png';
import volcano from '../public/volcano.png';
import yahooFinanceLogo from '../public/YahooFinanceLogo.png';


import { useHistory } from "react-router-dom";
import SkipNextIcon from '@mui/icons-material/SkipNext';
import axios from "axios";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";


const styles = theme =>({
  root: {
    flexGrow: 1,
  },
  grid: {
    marginTop: 6,
    marginBottom: 6,
    flexGrow: 1,

  },
});




var frequency = 0

const Home = () => {
  const classes = useClasses(styles);

  // get user name
  const { authState, authService } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const history = useHistory();

  const [dowjoneChange, setDowjoneChange] = useState(null);
  const [sp500Change, setSp500Change] = useState(null);
  const [nasdqChange, setNasdqChange] = useState(null);

  const handleRoute = () => {
    history.push("/stock");
  }



  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      authService.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, authService]); // Update if authState changes
  // get user name

  const login = async () => {
    authService.login('/');
  };


  if (authState.isPending) {
    return (
      <div>Loading...</div>
    );
  }

  var today = new Date()
  var curHr = today.getHours()

  // market

  const optionsMarket = {
    method: 'GET',
    url: 'https://yh-finance.p.rapidapi.com/market/v2/get-quotes',
    params: { region: 'US', symbols: '^DJI,^GSPC,^IXIC' },
    headers: {
      'x-rapidapi-key': 'b57c38d11emsh32976aeb83f05afp113107jsn102d78a776e1',
      'x-rapidapi-host': 'yh-finance.p.rapidapi.com'
    }
  };

  if (frequency === 0) {
    // axios.get('get_quote.json').then(function (response) {
    axios.request(optionsMarket).then(function (response) {
      setDowjoneChange((response.data.quoteResponse.result[0].regularMarketChangePercent).toFixed(2))
      setSp500Change((response.data.quoteResponse.result[1].regularMarketChangePercent).toFixed(2))
      setNasdqChange((response.data.quoteResponse.result[2].regularMarketChangePercent).toFixed(2))
      frequency += 1
      console.log("get 3 indexes number: ", frequency)
    }).catch(function (error) {
      console.error(error);
    });
  }

  // market




  return (
    <div>
      <div>
        <h1 as="h1">{curHr < 12 ? `Good Morning` : (curHr >= 12 && curHr < 19) ? `Good Afternoon` : `Good Evening`}</h1>

        {authState.isAuthenticated && !userInfo
          && <div>Loading user information...</div>}

        {authState.isAuthenticated && userInfo
          && (
            <div>
              <p>
                Welcome back,&nbsp;

                {userInfo.name}
                !
              </p>
              <h3>Market Today</h3>
              {sp500Change >= 0.5 && nasdqChange >= 0.5 ? (
                <img src={sunny} alt="sunny" style={{ width: "100" }} />)
                : (sp500Change > -0.5 && sp500Change < 0.5) || (nasdqChange > -0.5 && nasdqChange < 0.5) ? (
                  <img src={cloudy} alt="cloudy" style={{ width: "100" }} />
                )
                  : (sp500Change > -1 && sp500Change < -0.5) || (nasdqChange > -1 && nasdqChange < -0.5) ? (
                    <img src={rainy} alt="rainy" style={{ width: "100" }} />
                  )
                    : sp500Change <= -1 && nasdqChange <= -1 && nasdqChange >= -2 && sp500Change >= -2 ? (
                      <img src={stormy} alt="stormy" style={{ width: "100" }} />
                    )
                      : sp500Change < -2 || nasdqChange < -2 ? (
                        <img src={volcano} alt="volcano" style={{ width: "100" }} />
                      )

                        : (
                          <div></div>
                        )}

              <div>
                <Grid container className={classes.grid} spacing={2} direction="row">
                  {dowjoneChange >= 0 ? (
                    <Grid item xs={3}>
                      <Box bgcolor="success.main" color="success.contrastText" p={2}>
                        <ArrowUpwardIcon />
                        &nbsp; DJIdx:  <b>{dowjoneChange}%</b>
                      </Box>
                    </Grid>
                  ) : dowjoneChange < 0 ? (
                    <Grid item xs={3}>
                      <Box bgcolor="error.main" color="error.contrastText" p={2}>
                        <ArrowDownwardIcon />
                        &nbsp; DJIdx: <b>{dowjoneChange}%</b>
                      </Box>
                    </Grid>
                  ) : (
                    <div></div>
                  )}
                  {sp500Change >= 0 ? (
                    <Grid item xs={3}>
                      <Box bgcolor="success.main" color="success.contrastText" p={2}>
                        <ArrowUpwardIcon />
                        &nbsp; SP500: <b>{sp500Change}%</b>
                      </Box>
                    </Grid>
                  ) : sp500Change < 0 ? (
                    <Grid item xs={3}>
                      <Box bgcolor="error.main" color="error.contrastText" p={2}>
                        <p>
                          <ArrowDownwardIcon />
                          &nbsp; SP500: <b>{sp500Change}%</b>
                        </p>
                      </Box>
                    </Grid>
                  ) : (
                    <div></div>
                  )}

                  {nasdqChange >= 0 ? (
                    <Grid item xs={3}>
                      <Box bgcolor="success.main" color="success.contrastText" p={2}>
                        <p>
                          <ArrowUpwardIcon />
                          &nbsp; Nasdaq: <b>{nasdqChange}%</b>
                        </p>
                      </Box>
                    </Grid>
                  ) : nasdqChange < 0 ? (
                    <Grid item xs={3}>
                      <Box bgcolor="error.main" color="error.contrastText" p={2}>
                        <p>
                          <ArrowDownwardIcon />
                          &nbsp; Nasdaq: <b>{nasdqChange}%</b>
                        </p>
                      </Box>
                    </Grid>
                  ) : (
                    <div></div>
                  )}
                </Grid>

              </div>
              <div>
                <Button style={{ marginBottom: 12 }}
                  onClick={handleRoute}
                  variant="contained"
                  color="primary">
                  Go to Stock Page &nbsp;<SkipNextIcon />
                </Button>
              </div>
              The app powered by AI for stock market data analysis and forecast.
              <li>Visit the
                {' '}
                <a href="/comment">Statistic Page</a>
                {' '}
                page to explore our analysis.</li>
              <li>Visit the
                {' '}
                <a href="/profile">My Profile</a>
                {' '}
                page to take a look inside the ID token.</li>
              {/* <p>Current time {getCurrentDate()}</p> */}


              {/* <NewsApi newsapi_url={newsapi_url}/> */}
            </div>


          )}

        {!authState.isAuthenticated
          && (
            <div>
              <section>
                <p><b>Stock Watcher</b> collects realtime stock market data, </p>
                <p> Processes analysis by AI and Data Science methods on your selected Equity. You can measure your current position  and improve your strategy </p>
                <p> Start Your Stock Watcher Today!</p>
                <br />
                <Button variant="contained" color="primary" onClick={login}>Login</Button>
              </section>
              <br></br>
              <div>
                <span>Powered By &nbsp;</span><img src={yahooFinanceLogo} alt="yahoo" style={{ width: "80", display: 'inline' }} />
              </div>
            </div>
          )}

      </div>
    </div>
  );
};
export default Home;
