const CLIENT_ID = process.env.CLIENT_ID;
const ISSUER = process.env.ISSUER || 'https://dev-1093488.okta.com/oauth2/default';
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const OKTA_redirectUri = process.env.LOCAL_URL || 'https://www.snowbirdcloud.com'; //'http://localhost:3000'; 

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: `${OKTA_redirectUri}/implicit/callback`,  //'https://okta-reactjs-app.uc.r.appspot.com/implicit/callback' //`${OKTA_redirectUri}/implicit/callback`
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
  resourceServer: {
    messagesUrl: "https://mingfei-api.uc.r.appspot.com/api/cities", // not used
  },
};
